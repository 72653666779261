<template>
	<v-dialog v-model="dialogVacante.estatus" max-width="900">
	  <v-card>
      <v-card-title primary-title >
        Datos Vacante
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="dataVancante">

        <b>Puesto:</b> {{ dataVancante.puesto }}
        <br/>
        <b>Plantel: </b>{{ dataVancante.plantel }}
        <br/>
        <b>Fecha de solicitud: </b>{{ dataVancante.fecha }}
        <br/>
        <b>Tiempo transcurrido: </b>{{ dataVancante.dias }}

      </v-card-text>
      <v-card-actions v-if="dataVancante">
        <v-btn 
          color="black"   
          dark 
          small
          tile
          @click="dialogVacante = false"
        >
          <v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          color="green"   
          dark 
          small
          tile
          @click="$emit('addSolicitud')"
          v-if="!dataVancante.id"
        >
          Solicitar
          <v-icon small right>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
	export default{

		props:[
			'dialogVacante',
			'dataVancante'
		]

	}
</script>