<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row v-if="( [18,19].includes( getdatosUsuario.idpuesto ) && [568,626,7,1312,161,1248,1196,1489,1312,28,1778].includes( getdatosUsuario.iderp ) ) || ![18,19].includes( getdatosUsuario.idpuesto )">
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Coordinación de sucursales</span>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2"
              @click="dialogVacantes.estatus =true"
              small
              tile
            >
              Vacantes
            </v-btn>

	  		    <v-btn
              color="primary"
              dark
              @click="initialize"
              small
              tile
            >
              Actualizar
            </v-btn>
            
	  		  </v-card-title>
          
          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Fecha"
                  dense
                  filled
                  v-model="fecha"
                  class="ms-4"
                  type="date"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <label>Elige una escuela</label>
                <v-radio-group
                  v-model="escuela"
                  row
                  hide-details
                >
                  <v-radio
                    label="Inbi"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Fast"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Todas"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Elige una opción para la sucursal</label>
                <v-radio-group
                  v-model="validada"
                  row
                  hide-details
                >
                  <v-radio
                    label="Validada"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Sin Validar"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Sin huella"
                    :value="4"
                  ></v-radio>
                  <v-radio
                    label="Todas"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Elige el estatus de la huella</label>
                <v-radio-group
                  v-model="opHuella"
                  row
                  hide-details
                >
                  <v-radio
                    label="Con"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Sin"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Todas"
                    :value="3"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Elige un turno</label>
                <v-radio-group
                  v-model="opTurno"
                  row
                  hide-details
                >
                  <v-radio
                    label="Matutino"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Vespertino"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>


            </v-row>

            <v-row>
              <v-col cols="12" class="mb-2">
                <b>Planteles:</b> {{ filterEmpleados.length }}<br/>
                <b>Validadas:</b> {{ filterEmpleados.filter( el => el.confirmado && el.abrio ).length }}<br/>
                <b>Faltan:</b> {{ filterEmpleados.filter( el => !el.confirmado && el.abrio ).length }}<br/>
                <b>Avance:</b> {{ ( ( filterEmpleados.filter( el => el.confirmado && el.abrio ).length / filterEmpleados.length ) * 100 ).toFixed( 2 ) }}<br/>
              </v-col>
            </v-row>

  				  <v-data-table
  				    :headers="headers"
  				    :items="filterEmpleados"
  				    class="elevation-0"
              :search="search"
              dense
              :items-per-page="empleados.length"
              hide-default-footer
  				  >

              <!-- Validación de apertura -->
              <template v-slot:item.apertura="{ item }">
                <v-chip small :color="item.abrio && item.confirmado ? 'green' : item.abrio ? 'yellow' : 'red'" :dark="item.abrio && item.confirmado ? true : item.abrio ? false : true"> 
                  {{ item.apertura }}
                </v-chip>

                <br/>

                <v-chip class="my-4" small color="orange" v-if="item.abrio && !item.confirmado" dark @click=" plantelSeleccionado = item , dialogValidarSucursal = true"> 
                  Clic para validar
                </v-chip>

                <v-chip class="my-4" small color="green" v-if="item.abrio && item.confirmado" dark @click="plantelSeleccionado = item.confirmado, dialgoApertura = true"> 
                  Clic para ver
                </v-chip>

              </template>

              <!-- Validación de apertura -->
              <template v-slot:item.corteCaja="{ item }">
                <v-tooltip bottom v-if="item.corteCaja">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="green"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      Validado
                    </v-chip>
                  </template>
                  <span v-if="item.corteCaja">
                    Aceptó: {{ item.corteCaja.nombre_completo }}
                    <br/>
                    Hora: {{ item.corteCaja.fecha_creacion }}
                  </span>
                </v-tooltip>

                <v-chip class="my-4" small color="yellow" v-else @click="validarCorte = true, plantelSeleccionado = item"> 
                  Clic para validar
                </v-chip>
              </template>

              <!-- Validar que los teachers estén completos -->
              <template v-slot:item.validaTeachers="{ item }">
                <v-tooltip bottom v-if="item.plantelCompleto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="green"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      Validado
                    </v-chip>
                  </template>
                  <span v-if="item.plantelCompleto">
                    Aceptó: {{ item.plantelCompleto.nombre_completo }}
                    <br/>
                    Hora: {{ item.plantelCompleto.fecha_creacion }}
                  </span>
                </v-tooltip>

                <v-chip class="my-4" 
                  small 
                  :color="item.teachers_completos ? 'yellow' : 'red'" 
                  v-else 
                  @click="dialogAsistenciasTeacher = true, plantelSeleccionado = item, teacherAsistencia = item.teachers"
                > 
                  Clic para validar
                </v-chip>

              </template>

              <!-- Validación de las coordinadoras -->
              <template v-slot:item.coordi="{ item }">
                
                <span
                  v-for="( coordi, i ) in item.coordi" 
                  :key="i"
                >
                  <v-chip 
                    small 
                    :color="coordi.asistencia == 1 ? 'green' : 'red'"
                    @click="(encargadas_telefonos = [coordi]),(reemplazoAux = coordi.reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                  	dark
                    v-if="( opHuella == 1 && coordi.asistencia == 1 ) || ( opHuella == 2 && !coordi.asistencia ) || opHuella == 3"
                  >
                  	  <v-icon left v-if="coordi.asistencia == 1">mdi-circle-medium</v-icon>
                      <v-icon left v-if="coordi.reemplazo == 1">mdi-skull</v-icon>
                  	  {{ coordi.nombre_usuario }} 
                      <v-icon class="ml-2" @click="eliminarCoordi( coordi )">mdi-close</v-icon>
                  </v-chip>
                </span>
                <v-chip v-if="!item.coordi.length" small color="red" dark @click="agregarCoordi( item )"> 
              		Agregar
              	</v-chip>
              </template>

              <!-- Validación de los Teacher Líder -->
              <template v-slot:item.teacherslider="{ item }">
                <span
                  v-for="( teacher, i ) in item.teacherslider" 
                  :key="i"
                >
                  <v-chip 
                    small 
                    :color="teacher.asistencia == 1 ? 'green' : 'red'"
                    @click="(encargadas_telefonos = [teacher]),(reemplazoAux = teacher.reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                    dark
                    v-if="( opHuella == 1 && teacher.asistencia == 1 ) || ( opHuella == 2 && !teacher.asistencia ) || opHuella == 3"
                  >
                      <v-icon left v-if="teacher.asistencia == 1">mdi-circle-medium</v-icon>
                      <v-icon left v-if="teacher.reemplazo == 1">mdi-skull</v-icon>
                      {{ teacher.nombre_completo }} 
                      <v-icon class="ml-2" @click="eliminarTeacher( teacher )">mdi-close</v-icon>
                  </v-chip>
                </span>
                <v-chip v-if="!item.teacherslider.length" small color="red" dark @click="agregarTeacher( item )"> 
                  Agregar
                </v-chip>
              </template>


              <!-- <template v-slot:item.vendedoras="{ item }">
                <v-chip small v-for="( vendedora, i ) in item.vendedoras" 
                	:key="i" :color="vendedora.asistencia == 1 ? 'green' : 'red'" 
                	dark @click="(encargadas_telefonos = [vendedora]),(reemplazoAux = vendedora.reemplazo), (dialogTelefonos = true)">
                	  <v-icon left v-if="vendedora.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="vendedora.reemplazo == 1">mdi-skull</v-icon> 
                	  {{ vendedora.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.vendedorasCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.vendedoras.length" small @click="dialogAddVacante( item, 'VENDEDORA' )"> 
              		VACANTE 
              	</v-chip>
              </template> -->

              <template v-slot:item.vendedoras_2="{ item }">
                <span
                  v-for="( vendedora, i ) in item.vendedoras_2" 
                  :key="i"
                >
                  <v-chip 
                    small
                    :color="vendedora.asistencia == 1 ? 'green' : 'red'" 
                    dark @click="(encargadas_telefonos = [vendedora]),(reemplazoAux = vendedora.reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                    v-if="( opHuella == 1 && vendedora.asistencia == 1 ) || ( opHuella == 2 && !vendedora.asistencia ) || opHuella == 3"
                  >
                      <v-icon left v-if="vendedora.asistencia == 1">mdi-circle-medium</v-icon>
                      <v-icon left v-if="vendedora.reemplazo == 1">mdi-skull</v-icon> 
                      {{ vendedora.nombre_usuario }} 
                      <v-icon class="ml-2" @click="eliminarVendedora( vendedora )">mdi-close</v-icon>
                  </v-chip>
                </span>

                <v-icon small color="orange" v-if="item.vendedoras_2.length">mdi-plus</v-icon> 

                <v-chip color="red" dark v-if="!item.vendedoras_2.length" small @click="agregarVendedora( item )"> 
                  Agregar 
                </v-chip>
              </template>

              <!-- <template v-slot:item.intendencia="{ item }">
                <v-chip small v-for="( intendencia, i ) in item.intendencia" 
                	:key="i" :color="intendencia.asistencia == 1 ? 'green' : 'red'" 
                  @click="(encargadas_telefonos = [intendencia]),(reemplazoAux = intendencia.reemplazo), (dialogTelefonos = true)"
                	dark>
                	  <v-icon left v-if="intendencia.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="intendencia.reemplazo == 1">mdi-skull</v-icon> 
                	  {{ intendencia.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.intendenciaCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.intendencia.length" small @click="dialogAddVacante( item , 'INTENDENCIA' )"> 
              		VACANTE 
              	</v-chip>
              </template> -->

  				    <template v-slot:item.encargadas="{ item }">
                <span
                  v-for="( encargada, i ) in item.encargadas" 
                  :key="i"
                >
                  <v-chip 
                  	small 
                  	:color="encargada.asistencia == 1 && encargada.validada ? 'green' : encargada.asistencia == 1 ? 'yellow' : 'red' " 
                  	:dark="encargada.asistencia == 1 && encargada.validada ? true : encargada.asistencia == 1 ? false : true " 
                    @click="(encargadas_telefonos = [encargada]),(reemplazoAux = encargada.reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                    v-if="( opHuella == 1 && encargada.asistencia == 1 ) || ( opHuella == 2 && !encargada.asistencia ) || opHuella == 3"
                  >
                  	<v-icon left v-if="encargada.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="encargada.reemplazo == 1">mdi-skull</v-icon>
                  	{{ encargada.nombre_usuario }} 
                  </v-chip>
                </span>
                
                <v-chip v-if="item.encargadasCant == 0" small color="white"> 
              		N/A
              	</v-chip>

                <v-chip v-else-if="!item.encargadas.length" small @click="dialogAddVacante( item, 'ENCARGADA' )"> 
              		VACANTE 
              	</v-chip>
              </template>

              <template v-slot:item.recepcionistaMat="{ item }">
                <span
                  v-for="( recepcionista, i ) in item.recepcionistaMat" 
                  :key="i"
                >
                  <v-chip small
                    :color="recepcionista.asistencia == 1 && recepcionista.validada ? 'green' : recepcionista.asistencia == 1 ? 'yellow' : 'red' " 
                    :dark="recepcionista.asistencia == 1 && recepcionista.validada ? true : recepcionista.asistencia == 1 ? false : true " 
                    @click="(encargadas_telefonos = [recepcionista]),(reemplazoAux = recepcionista.reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                    v-if="( opHuella == 1 && recepcionista.asistencia == 1 ) || ( opHuella == 2 && !recepcionista.asistencia ) || opHuella == 3"
                  > 
                  	<v-icon left v-if="recepcionista.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="recepcionista.reemplazo == 1">mdi-skull</v-icon> 
                  	{{ recepcionista.nombre_usuario }} 
                  </v-chip>
                </span>

                <v-chip v-if="item.recepcionistaMatCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                
                <v-chip v-else-if="!item.recepcionistaMat.length" small @click="dialogAddVacante( item, 'RECEP1' )"> 
              		VACANTE 
              	</v-chip>
              </template>

              <template v-slot:item.recepcionistaVes="{ item }">
                <v-chip 
                  small 
                  v-if="item.recepcionistaVes.length && ( ( opHuella == 1 && item.recepcionistaVes[0].asistencia == 1 ) || ( opHuella == 2 && !item.recepcionistaVes[0].asistencia ) || opHuella == 3 )"
                  :color="item.recepcionistaVes[0].asistencia == 1 && item.recepcionistaVes[0].asistencia.validada ? 'green' : item.recepcionistaVes[0].asistencia == 1 ? 'yellow' : 'red' " 
                  :dark="item.recepcionistaVes[0].asistencia == 1 && item.recepcionistaVes[0].asistencia.validada ? true : item.recepcionistaVes[0].asistencia == 1 ? false : true " 
                  @click="(encargadas_telefonos = [item.recepcionistaVes[0]]),(reemplazoAux = item.recepcionistaVes[0].reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                > 
                	<v-icon left v-if="item.recepcionistaVes[0].asistencia == 1">mdi-circle-medium</v-icon>
                  <v-icon left v-if="item.recepcionistaVes[0].reemplazo == 1">mdi-skull</v-icon> 
                	{{ item.recepcionistaVes[0].nombre_usuario }} 
                </v-chip>
                <v-chip v-else-if="item.recepcionistaVesCant == 0" small color="white"> 
              		NA
              	</v-chip>
                <v-chip v-else-if="!item.recepcionistaVes.length" small @click="dialogAddVacante( item, 'RECEP2' )"> 
              		VACANTE 
              	</v-chip>


                <v-chip 
                  small 
                  v-if="item.recepcionistaVes.length == 2 && ( ( opHuella == 1 && item.recepcionistaVes[1].asistencia == 1 ) || ( opHuella == 2 && !item.recepcionistaVes[1].asistencia ) || opHuella == 3 )"
                  :color="item.recepcionistaVes[1].asistencia == 1 && item.recepcionistaVes[1].asistencia.validado ? 'green' : item.recepcionistaVes[1].asistencia == 1 ? 'yellow' : 'red' " 
                  :dark="item.recepcionistaVes[1].asistencia == 1 && item.recepcionistaVes[1].asistencia.validado ? true : item.recepcionistaVes[1].asistencia == 1 ? false : true "
                  @click="(encargadas_telefonos = [item.recepcionistaVes[1]]),(reemplazoAux = item.recepcionistaVes[1].reemplazo), (dialogTelefonos = true), ( plantelSeleccionado = item )"
                > 
                  <v-icon left v-if="item.recepcionistaVes[1].asistencia == 1">mdi-circle-medium</v-icon>
                  <v-icon left v-if="item.recepcionistaVes[1].reemplazo == 1">mdi-skull</v-icon> 
                  {{ item.recepcionistaVes[1].nombre_usuario }} 
                </v-chip>
                <v-chip v-else-if="item.recepcionistaVesCant <= 1" small color="white"> 
                  NA
                </v-chip>
                <v-chip v-else small @click="dialogAddVacante( item , 'RECEP3')"> 
                  VACANTE 
                </v-chip>
              </template>




  				    <template v-slot:no-data>
  				      <v-btn
  				        color="primary"
  				        @click="initialize"
  				        small
  				      >
  				        Actualizar
  				      </v-btn>
  				    </template>
  				  </v-data-table>
              
          </v-card-text>

				</v-card>
		  </v-col>
    </v-row>

    <v-dialog v-model="dialogTelefonos"max-width="900">
      <v-card>
        <v-card-title primary-title >
          <v-icon left>mdi-account</v-icon> Validación de Entrada
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text >

          <div v-if="encargadas_telefonos.length">

            <div><b>Personal:</b> {{ encargadas_telefonos[0].nombre_completo }}</div>  
            <div><b>Hora Entrada:</b> {{ encargadas_telefonos[0].hora_registro }}</div>  
            <div><b>Estatus:</b> {{ encargadas_telefonos[0].asistencia ? 'Con Registro' : 'Sin Registro' }}</div>  
            <div><b>Validado:</b> {{ encargadas_telefonos[0].validada ? 'Con Validación' : 'Sin validación' }}</div>  
            <div v-if="encargadas_telefonos[0].validada"><b>Validó:</b> {{ encargadas_telefonos[0].validada.nombre_completo }}</div>  


            <v-alert
              dense
              outlined
              type="info"
            >
              Yo <b>{{ getdatosUsuario.nombre_completo }}</b>, confirmo que: <b>{{ encargadas_telefonos[0].nombre_completo }}</b> registró su entrada el día <b>{{ fecha }}</b> a las <b>{{ encargadas_telefonos[0].hora_registro }}</b> y que no requiere ningún cambio de horario.
            </v-alert>

            <v-btn 
              small
              tile
              color="success"
              class="mr-2"
              @click="validaEncargada( encargadas_telefonos[0] )"
              v-if="encargadas_telefonos[0].asistencia && !encargadas_telefonos[0].validada "
            >
              Validar Entrada <v-icon small right>mdi-check</v-icon>
            </v-btn>

            <v-btn 
              small
              tile
              color="orange"
              dark
              @click="openModalCambios.estatus = true"
              v-if="encargadas_telefonos[0].asistencia && !encargadas_telefonos[0].validada"
            >
              Solicitar cambio de horario <v-icon small right>mdi-file-document</v-icon>
            </v-btn>

          </div>

          <br/>
          <br/>

          <v-data-table
            :headers="headersTelefonos"
            :items="encargadas_telefonos"
            class="elevation-0"
            dense
          > 
          <template v-slot:item.nombre_completo="{ item }">
            <v-row align="center">
              <p class="ma-0 me-5">{{ item.nombre_completo }}</p>
              <v-checkbox
              v-model="reemplazoAux"
              :label="`Reemplazo`"
              v-if="getdatosUsuario.idpuesto != 31"
              ></v-checkbox>
            </v-row>
          </template>           
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            @click="dialogTelefonos = false"
            top
            absolute
            icon
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="getdatosUsuario.idpuesto != 31"  dark @click="(encargadas_telefonos[0].reemplazo = reemplazoAux), actualizarPuesto(encargadas_telefonos)">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCoordi"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Selecciona una coordinadora para este plantel
        </v-card-title>
        <v-card-text v-if="plantelSeleccionado" class="black--text">
          <b>Plantel:</b> {{ plantelSeleccionado.plantel }}

          <br/>
          <v-autocomplete
            filled 
            dense
            clearable
            v-model="coordinadora"
            :items="coordinadoras"
            label="Selecciona la coordinadora"
            item-text="nombre_completo"
            item-value="id_usuario"
            class="mt-4"
            hide-details
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            dark
            small
            tile
            @click="dialogCoordi = false"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            small
            tile
            @click="saveCoordi( )"
          >
            Guardar
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- AGREGAR VENDEDORA -->
    <v-dialog
      v-model="dialogVendedora"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Selecciona una coordinadora para este plantel
        </v-card-title>
        <v-card-text v-if="plantelSeleccionado" class="black--text">
          <b>Plantel:</b> {{ plantelSeleccionado.plantel }}

          <br/>
          <v-autocomplete
            filled 
            dense
            clearable
            v-model="vendedora"
            :items="vendedoras"
            label="Selecciona la vendedora"
            item-text="nombre_completo"
            item-value="id_usuario"
            class="mt-4"
            hide-details
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            dark
            small
            tile
            @click="dialogVendedora = false"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            small
            tile
            @click="saveVendedora( )"
          >
            Guardar
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Mostrar teachers -->
    <v-dialog
      v-model="dialogAsistenciasTeacher"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Lista de teacher y asistencia
        </v-card-title>
        <v-card-text v-if="plantelSeleccionado" class="black--text">
          <b>Plantel:</b> {{ plantelSeleccionado.plantel }}

          <br/>
          <v-data-table
            :headers="headersTeachers"
            :items="teacherAsistencia"
            class="elevation-0"
            dense
          > 

            <template v-slot:item.asistencia="{ item }">
              <v-chip :color="item[`asistencia_${opTeacher}`] ? 'green' : 'red' " dark small> {{ item[`asistencia_${opTeacher}`] ? 'Con asistencia' : 'No ha llegado' }} </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            dark
            small
            tile
            @click="dialogAsistenciasTeacher = false"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            small
            tile
            @click="saveConfirmacionTeacherCompletos( )"
          >
            <v-icon left small>mdi-close</v-icon>
            Confirmar!!!!!
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- AGREGAR TEACHER -->
    <v-dialog
      v-model="dialogTeachers"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Selecciona un Coordinador Académico para este plantel
        </v-card-title>
        <v-card-text v-if="plantelSeleccionado" class="black--text">
          <b>Plantel:</b> {{ plantelSeleccionado.plantel }}

          <br/>
          <v-autocomplete
            filled 
            dense
            clearable
            v-model="teacher"
            :items="teachersLider"
            label="Selecciona el Coordinador Académico"
            item-text="nombre_completo"
            item-value="id_usuario"
            class="mt-4"
            hide-details
          >
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            dark
            small
            tile
            @click="dialogTeachers = false"
          >
            <v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            small
            tile
            @click="saveTeacher( )"
          >
            Guardar
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- AGREGAR TEACHER -->
    <v-dialog
      v-model="dialogValidarSucursal"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Validación de Apertura
        </v-card-title>
        <v-card-text class="black--text text-center">
          ¿Estás de acuerdo con que la sucursal se encuentra abierta en este momento?

          <br/>
          <br/>

          <span v-if="plantelSeleccionado">
            <b>Plantel:</b> {{ plantelSeleccionado.plantel }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            small
            icon
            absolute
            top
            right
            @click="dialogValidarSucursal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            block
            @click="saveConfirmacion( )"
          >
            Aceptar apertura de sucursal
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- AGREGAR TEACHER -->
    <v-dialog
      v-model="dialgoApertura"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Apertura validada
        </v-card-title>
        <v-card-text class="black--text" v-if="plantelSeleccionado">
          <b>Coordinadora:</b> {{ plantelSeleccionado.nombre_completo }}
          <br/>
          <b>Plantel:</b> {{ plantelSeleccionado.plantel }}
          <br/>
          <b>Fecha confirmaicón:</b> {{ plantelSeleccionado.fecha_creacion }}
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            small
            icon
            absolute
            top
            right
            @click="dialgoApertura = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"
            dark
            block
            @click="saveConfirmacion( )"
          >
            Aceptar apertura de sucursal
            <v-icon right small>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Validar el corte -->
    <v-dialog
      v-model="validarCorte"
      max-width="600px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title class="text-subtitle-1">
        </v-card-title>
        <v-card-text class="black--text text-h5 text-center" v-if="plantelSeleccionado">
          <br/>
          ¿Estás segura de que el corte de {{ plantelSeleccionado.plantel }} ya se ingresó a la caja?
          <br/>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            icon
            top
            right
            absolute
            @click="validarCorte = false"
          ><v-icon>mdi-close</v-icon></v-btn>

          <v-btn 
            color="black"
            class="white--text"
            @click="validarCorte = false"
          >No, cancelar</v-btn>

          <v-spacer></v-spacer>

          <v-btn 
            color="#f073f0"
            @click="validarCorteSuccess( )"
          >Sí, super segura!</v-btn>
        </v-card-actions>
      </v-card>      
    </v-dialog>

    <!-- Solicitar el cambio de horario -->
    <ModalCambioHorario
      :openModalCambios="openModalCambios"
      v-if="openModalCambios.estatus"
    />

    <!-- Ver el dialogo de vacantes -->
    <dialogVacantes
      v-if="dialogVacantes.estatus"
      :dialogVacantes="dialogVacantes"
      :ultimoCambio="ultimoCambio"
      :colaboradoresTotales="colaboradoresTotales"
    />

    <!-- Ver el dialogo de vacantes -->
    <dialogVacante
      v-if="dialogVacante.estatus"
      :dialogVacante="dialogVacante"
      :dataVancante="dataVancante"
      @addSolicitud="addSolicitud"
    />


	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
  import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'

  import ModalCambioHorario from '@/components/nomina/ModalCambioHorario.vue'
  import dialogVacantes     from '@/components/visualizador/dialogVacantes.vue'
  import dialogVacante      from '@/components/visualizador/dialogVacante.vue'

  export default {

  	components:{
      Alerta,
      carga,
      ModalCambioHorario,
      dialogVacantes,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      reemplazoAux: 0,

      dialog: false,
      dialogDelete: false,
      dialogVacantes:{
        estatus: false
      },
      dialogTelefonos: false,
      encargadas_telefonos : [],
      colaboradoresTotales : [],
      puestosTotales : [],
      ultimoCambio : '',
      coordinadoras: [],
      coordinadora: null,
      vendedora: null,
      vendedoras:[],
      headers: [
        { text: 'Plantel',                     value: 'plantel',           },
        { text: 'Apertura',                    value: 'apertura',          },
        { text: 'Coordinador de zona',         value: 'coordi'             },
        { text: 'Coord Académico',             value: 'teacherslider'      },
        { text: 'Vendedora',                   value: 'vendedoras_2'       },
        { text: 'Encargada',                   value: 'encargadas',        },
        { text: 'Recepcionista Matutina',      value: 'recepcionistaMat',  },
        { text: 'Recepcionista Vespertina',    value: 'recepcionistaVes',  },
        { text: 'Teachers'                ,    value: 'validaTeachers',    },
        { text: 'Corte',                       value: 'corteCaja' ,        },
      ],

      headersTelefonos: [
        { text: 'Nombre'                    , value: 'nombre_completo' },
        { text: 'Telefono1'                 , value: 'telefono1' },
        { text: 'Telefono2'                 , value: 'telefono2' },
      ],
      empleados: [],
      search:'',

      payload:{
        totalesINBI:0,
        totalesFAST:0,
        totalesVacantesCubrirINBI:0,
        totalesVacantesCubrirFAST:0,
        totalesOcupamosINBI:0,
        totalesOcupamosFAST:0,
        totalesReemplazosINBI:0,
        totalesReemplazosFAST:0,
        totalesVacantesINBI:0,
        totalesVacantesFAST:0,
      },

      dataVancante: null,
      
      dialogVacante:{
        estatus: false
      },
      
      plantelSeleccionado: null,
      dialogCoordi: false,
      dialogVendedora: false,
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      dialogTeachers: false,
      teacher: null,
      teachersLider:[],
      dialogValidarSucursal: false,
      dialgoApertura: false,
      escuela: 3,
      validada: 3,
      opHuella: 3,

      openModalCambios:{
        estatus: false,
      },

      validarCorte: false,

      opTurno: 1,

      dialogAsistenciasTeacher: false,
      teacherAsistencia: [],
      headersTeachers:[
        { text: 'Nombre'        , value: 'nombre'      },
        { text: 'Asistencia'    , value: 'asistencia'  },
        { text: 'Hora inicio'   , value: 'hora_inicio' }
      ],
      opTeacher:0
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      console.log( 'gohohpi',this.getdatosUsuario )
      await this.initialize()
    },

    computed: {
      ...mapGetters("login", ["getdatosUsuario", "getLogeado"]),

      filterEmpleados( ){

        let data = this.empleados

        if( this.escuela != 3 ){

          if( this.escuela == 1 ){
            data = data.filter( el => !el.plantel.match('FAST') ) 
          }else{
            data = data.filter( el => el.plantel.match('FAST') )
          }

        }

        if( this.validada != 3 ){

          if( this.validada == 1 ){
            data = data.filter( el => el.abrio && el.confirmado ) 
          }else if( this.validada == 2 ){
            data = data.filter( el => el.abrio && !el.confirmado ) 
          }else{
            data = data.filter( el => !el.abrio && !el.confirmado )
          }

        }

        return data
      }


    },

    methods: {
      initialize () {
        this.empleados = []
        this.cargar = true

        this.colaboradoresTotales = Object.assign({}, this.payload )
        this.$http.get('puestos.plantel').then(response=>{
          this.puestosTotales = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })

        this.$http.get('trabajador.ultimocambio').then(response=>{
          this.ultimoCambio = response.data
          console.log(this.ultimoCambio)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })


        const payload = {
          dia: this.fecha,
          turno: this.opTurno
        }

        return this.$http.post('asistencias.visuales', payload ).then(response=>{

          this.coordinadoras  = response.data.personal.filter( el => el.idpuesto == 73 )
          this.vendedoras     = response.data.personal.filter( el => el.idpuesto != 82)
          this.teachersLider  = response.data.personal.filter( el => el.idpuesto == 82 )
        	this.empleados      = response.data.plantelesActivos
          this.opTeacher      = response.data.opTeacher

          this.empleados.forEach(element => {
            if(element.plantel.includes('FAST')){
              this.colaboradoresTotales.totalesFAST = this.colaboradoresTotales.totalesFAST + element.encargadas.length + element.vendedoras.length + 
              element.coordi.length + element.recepcionistaMat.length + element.recepcionistaVes.length
              + element.intendencia.length;
              element.coordi.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.encargadas.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.vendedoras.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.recepcionistaMat.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.recepcionistaVes.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.intendencia.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              
              this.puestosTotales.forEach(element2 => {
                if(element2.idplantel == element.idplantel){
                  this.colaboradoresTotales.totalesOcupamosFAST = this.colaboradoresTotales.totalesOcupamosFAST + element2.Cantidad
                }
              });

            } else{
               this.colaboradoresTotales.totalesINBI =this.colaboradoresTotales.totalesINBI + element.encargadas.length + element.vendedoras.length + 
               element.coordi.length + element.recepcionistaMat.length + element.recepcionistaVes.length
               + element.intendencia.length;

               element.coordi.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.encargadas.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.vendedoras.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.recepcionistaMat.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.recepcionistaVes.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.intendencia.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });

               this.puestosTotales.forEach(element2 => {
                if(element2.idplantel == element.idplantel){
                  this.colaboradoresTotales.totalesOcupamosINBI = this.colaboradoresTotales.totalesOcupamosINBI + element2.Cantidad
                }
              });
            }
          });
          this.colaboradoresTotales.totalesVacantesCubrirINBI = this.colaboradoresTotales.totalesOcupamosINBI - this.colaboradoresTotales.totalesINBI
          this.colaboradoresTotales.totalesVacantesCubrirFAST = this.colaboradoresTotales.totalesOcupamosFAST - this.colaboradoresTotales.totalesFAST
          this.colaboradoresTotales.totalesVacantesINBI = this.colaboradoresTotales.totalesVacantesCubrirINBI + this.colaboradoresTotales.totalesReemplazosINBI
          this.colaboradoresTotales.totalesVacantesFAST = this.colaboradoresTotales.totalesVacantesCubrirFAST + this.colaboradoresTotales.totalesReemplazosFAST
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      actualizarPuesto(item){
        this.cargar = true;
         this.$http.put('trabajador.reemplazo/'+item[0].reemplazo +'/' +item[0].id_trabajador+'/'+this.getdatosUsuario.iderp).then(response=>{
          this.initialize()
          this.validarSuccess ( response.data )
         }).catch( error =>{
           this.validarError( error.response.data.message )
         }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      agregarCoordi( item ){

        this.plantelSeleccionado = item
        this.dialogCoordi = true

      },

      agregarTeacher( item ){

        this.plantelSeleccionado = item
        this.dialogTeachers = true

      },


      eliminarVendedora( item ){
        this.cargar = true;
        this.dialogTelefonos = false

        console.log( item) 

        this.$http.post('plantel.vendedora.delete', item ).then(response=>{
          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTeachers  = false
          this.dialogTelefonos = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      agregarVendedora( item ){

        this.plantelSeleccionado = item
        this.dialogVendedora = true

      },

      eliminarCoordi( item ){

        this.cargar = true;
        this.dialogTelefonos = false

        this.$http.post('plantel.coordinadora.delete', item ).then(response=>{
          this.initialize( )
          this.dialogCoordi = false
          this.dialogTelefonos = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },

      saveCoordi( ){
        this.cargar = true;

        const payload = {
          id_usuario: this.coordinadora,
          id_plantel: this.plantelSeleccionado.idplantel,
          modifico:   this.getdatosUsuario.iderp
        }

        this.$http.post('plantel.coordinadora', payload ).then(response=>{
          this.initialize( )
          this.dialogCoordi = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      saveVendedora( ){
        this.cargar = true;

        const payload = {
          id_usuario: this.vendedora,
          id_plantel: this.plantelSeleccionado.idplantel,
          dia       : this.fecha,
          modifico  : this.getdatosUsuario.iderp
        }

        this.$http.post('plantel.vendedora', payload ).then(response=>{
          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      saveTeacher( ){
        this.cargar = true;

        const payload = {
          id_usuario: this.teacher,
          id_plantel: this.plantelSeleccionado.idplantel,
          modifico  : this.getdatosUsuario.iderp
        }

        this.$http.post('plantel.teachers', payload ).then(response=>{
          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTeachers  = false
          this.dialogTelefonos = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      saveConfirmacion( ){
        this.cargar = true;

        const payload = {
          id_coordinadora: this.getdatosUsuario.iderp,
          id_plantel     : this.plantelSeleccionado.idplantel,
          fecha          : this.fecha,
        }

        this.$http.post('validar.apertura', payload ).then(response=>{
          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTeachers  = false
          this.dialogTelefonos = false
          this.dialogValidarSucursal = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      validaEncargada( item ){

        let ruta = item.idpuesto == 19 ? 'plantel.recepcion' : 'plantel.encargada'

        this.cargar = true;

        const payload = {
          id_usuario: item.id_usuario,
          id_plantel: this.plantelSeleccionado.idplantel,
          dia       : this.fecha,
          modifico  : this.getdatosUsuario.iderp
        }
 
        this.$http.post(ruta, payload ).then(response=>{
          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTelefonos = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      eliminarTeacher( item ){

        this.cargar = true;
        this.dialogTelefonos = false

        this.$http.post('plantel.teachers.delete', item ).then(response=>{
          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTeachers  = false
          this.dialogTelefonos = false
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },

      validarCorteSuccess( ){

        this.cargar = true;

        const payload = {
          id_plantel: this.plantelSeleccionado.idplantel,
          fecha     : this.fecha,
          modifico  : this.getdatosUsuario.iderp
        }
 
        this.$http.post('validar.corte.entregado', payload ).then(response=>{

          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTelefonos = false
          this.validarCorte    = false
          this.validarSuccess ( response.data.message )

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },


      saveConfirmacionTeacherCompletos( ){

        this.cargar = true;

        const payload = {
          fecha     : this.fecha,
          turno     : this.opTurno,
          id_plantel: this.plantelSeleccionado.idplantel,
          modifico  : this.getdatosUsuario.iderp,
        }
 
        this.$http.post('validar.teachers.completos', payload ).then(response=>{

          this.initialize( )
          this.dialogVendedora = false
          this.dialogCoordi    = false
          this.dialogTelefonos = false
          this.validarCorte    = false
          this.dialogTeachers  = false
          this.dialogAsistenciasTeacher = false
          this.validarSuccess ( response.data.message )

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      editItem (item) {

      	this.alumno = item

       	if( item.escuela == 2 )
       		this.urlDirecta = this.urlFast + item.nombre_video
       	else
       		this.urlDirecta = this.urlInbi + item.nombre_video

        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      dialogAddVacante( item, puesto ){
        this.dataVancante = {
          idplantel: item.idplantel,
          plantel: item.plantel,
          puesto
        }

        let payload = {
          idplantel: this.dataVancante.idplantel,
          plantel  : this.dataVancante.plantel,
          puesto   : this.dataVancante.puesto,
          solicito: this.getdatosUsuario.iderp
        }

        this.cargar = true;
        this.$http.post('vacante.get', payload ).then(response=>{

          console.log( response.data )

          if( response.data ){
            this.dataVancante['dias']  = response.data.dias
            this.dataVancante['fecha'] = response.data.fecha_creacion
            this.dataVancante['id']    = response.data.idvacante_rh
          }

          this.dialogVacante.estatus = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        // Ver si hay una solicitud


      },

      addSolicitud( ){
        let payload = {
          idplantel: this.dataVancante.idplantel,
          plantel: this.dataVancante.plantel,
          puesto : this.dataVancante.puesto,
          solicito: this.getdatosUsuario.iderp
        }

        this.cargar = true;
        this.$http.post('vacante.add', payload ).then(response=>{
          this.dialogVacante.estatus = false
          this.initialize()
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }
    },
  }
  /*454*/
  /* 1305 */
</script>