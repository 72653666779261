<template>
	<v-dialog v-model="dialogVacantes.estatus" max-width="900">
	  <v-card>
	    <v-card-title primary-title >
	      <!-- <v-icon left>mdi-</v-icon>--> Vacantes 
	      <v-spacer></v-spacer>
	    </v-card-title>
	    <v-card-text >
	      <v-row>
	        <v-col v-if="ultimoCambio.length">
	          <div>
	            <p class="ma-0"><strong>Ultimo cambio realizado:</strong></p>
	            <p class="ma-0">Usuario: {{ ultimoCambio[0].nombre_completo }}</p>
	            <p>Fecha: {{ ultimoCambio[0].fecha_ultimo_cambio }}</p>
	          </div>
	        </v-col>
	      </v-row>
	    <v-simple-table>
	      <template v-slot:default>
	        <thead>
	          <tr>
	            <th class="text-left">
	            </th>
	            <th class="text-left" style="background-color: rgb(226, 233, 247);">
	              INBI
	            </th>
	            <th class="text-left"  style="background-color: rgb(236, 213, 213);">
	              FAST
	            </th>
	            <th class="text-left">
	              TOTAL
	            </th>
	          </tr>
	        </thead>
	        <tbody>
	          <tr>
	            <td>Total que ocupamos:</td>
	            <td>{{ colaboradoresTotales.totalesOcupamosINBI }}</td>
	            <td>{{ colaboradoresTotales.totalesOcupamosFAST }}</td>
	            <td>{{ colaboradoresTotales.totalesOcupamosINBI + colaboradoresTotales.totalesOcupamosFAST }}</td>
	          </tr>
	          <tr >
	            <td>Total de colaboradores:</td>
	            <td>{{ colaboradoresTotales.totalesINBI }}</td>
	            <td>{{ colaboradoresTotales.totalesFAST }}</td>
	            <td>{{ colaboradoresTotales.totalesINBI + colaboradoresTotales.totalesFAST }}</td>
	          </tr>
	          <tr>
	            <td>Vacantes por cubrir:</td>
	            <td>{{ colaboradoresTotales.totalesVacantesCubrirINBI }}</td>
	            <td>{{ colaboradoresTotales.totalesVacantesCubrirFAST }}</td>
	            <td>{{ colaboradoresTotales.totalesVacantesCubrirINBI + colaboradoresTotales.totalesVacantesCubrirFAST }}</td>
	          </tr>
	          <tr>
	            <td>Reemplazos:</td>
	            <td>{{ colaboradoresTotales.totalesReemplazosINBI }}</td>
	            <td>{{ colaboradoresTotales.totalesReemplazosFAST }}</td>
	            <td>{{ colaboradoresTotales.totalesReemplazosINBI + colaboradoresTotales.totalesReemplazosFAST }}</td>
	          </tr>
	          <tr>
	            <td>Vacantes totales:</td>
	            <td>{{ colaboradoresTotales.totalesVacantesINBI }}</td>
	            <td>{{ colaboradoresTotales.totalesVacantesFAST }}</td>
	            <td>{{ colaboradoresTotales.totalesVacantesINBI + colaboradoresTotales.totalesVacantesFAST }}</td>
	          </tr>
	          
	        </tbody>
	      </template>
	    </v-simple-table>
	    </v-card-text>
	    <v-card-actions>
	      <v-spacer></v-spacer>
	      <v-btn color="error"   dark @click="dialogVacantes.estatus = false">Cancelar</v-btn>
	    </v-card-actions>
	  </v-card>
  </v-dialog>
</template>

<script>
	export default{

		props:[
			'ultimoCambio',
			'colaboradoresTotales',
			'dialogVacantes'
		]

	}
</script>