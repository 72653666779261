<template>
	<v-dialog
		v-model="openModalCambios"
		max-width="800px"
	>
    <v-card>
      <v-card-title>
        <span class="text-subtitle-1">{{ formTitle }}</span>
        <v-spacer></v-spacer>
        <span class="text-subtitle-1">
        	<strong>ID: {{ editedItem.idsolicitud_cambio_horario }}</strong>
        </span>
      </v-card-title>

      <v-card-text>
        <v-row>

        	<v-col cols="12">
  		  		<v-autocomplete 
  		  			filled 
  		  			dense 
  		  			clearable 
  		  			v-model="editedItem.id_usuario" 
  		  			:items="usuarios"
							label="Selecciona un usuario" 
							hide-details
							item-text="nombre_completo" 
							item-value="id_usuario"
						>
						</v-autocomplete>
		  		</v-col>

		  		<br/>
		  		<br/>

		  		<v-row v-if="editedIndex < 0">
		  			<v-col cols="12">
		  				<v-card class="elevation-0 transparent">
		  					<v-card-text>
  		  				  <v-row>
			  		  			<v-col cols="12">
			  		  				<v-alert
									      dense
									      outlined
									      type="info"
									    >
									      Llena los siguientes y da clic en agregar para <strong>agregar</strong> los datos a la solicitud, en caso de ser un cambio de descanso, solo ingresa el día y clic en el check de descanso.
									    </v-alert>

			  		  			</v-col>


			  		  			<v-col cols="12">
			  		  				<label>Selecciona primera una opción</label>
			  		  				<v-radio-group
									      v-model="descanso"
									      row
									      v-if="editedIndex < 0"
									      hide-details
									    >
									      <v-radio
									        label="Horario"
									        :value="2"
									      ></v-radio>
									      <v-radio
									        label="Descanso"
									        :value="1"
									      ></v-radio>
									    </v-radio-group>
			  		  			</v-col>

			  		  			<v-col cols="12"  v-if="descanso">
			  		  				<v-card class="shadowCard">
			  		  					<v-card-text>
			  		  						<v-row>
								          	<v-col cols="12" >
								              <v-text-field
								                v-model="fecha"
								                label="Fecha"
								                filled
								                hide-details
								                dense
								                type="date"
								                v-if="descanso == 2"
								              ></v-text-field>

								              <v-radio-group
								              	v-if="descanso == 1"
													      v-model="dia_descanso"
													      row
													    >
													      <v-radio
													        label="Sabado"
													        :value="1"
													      ></v-radio>
													      <v-radio
													        label="Domingo"
													        :value="2"
													      ></v-radio>
													      <v-radio
													        label="Lunes"
													        :value="3"
													      ></v-radio>
													      <v-radio
													        label="Martes"
													        :value="4"
													      ></v-radio>
													      <v-radio
													        label="Miércoles"
													        :value="5"
													      ></v-radio>
													      <v-radio
													        label="Jueves"
													        :value="6"
													      ></v-radio>
													      <v-radio
													        label="Viernes"
													        :value="7"
													      ></v-radio>
													    </v-radio-group>

								            </v-col>

									          <v-col cols="12" md="6" v-if="editedIndex < 0 && descanso == 2">
								              <v-text-field
								                v-model="hora_inicio"
								                label="Hora inicio"
								                filled
								                hide-details
								                dense
								                type="time"
								              />
								            </v-col>

									          <v-col cols="12" md="6" v-if="editedIndex < 0 && descanso == 2">
								              <v-text-field
								                v-model="hora_final"
								                label="Hora final"
								                filled
								                hide-details
								                dense
								                type="time"
								              ></v-text-field>
								            </v-col>

								            <v-col cols="12" v-if="editedIndex < 0 && descanso">
								            	<v-textarea
								            		v-model="motivo"
								            		filled
								            		hide-details
								            		dense
								            		auto-grow
								            		:rows="3"
								            		label="Motivo"
								            	/>
								            </v-col>

			  		  						</v-row>
			  		  					</v-card-text>
			  		  					<v-card-actions>
						              <v-btn 
						              	color="orange"
						              	dark
						              	@click="agregarHorario()"
						              	tile
						              	block
						              >
						            		<v-icon small right>mdi-plus</v-icon> Agregar este dato especificamente
						            	</v-btn>
			  		  					</v-card-actions>
			  		  				</v-card>
			  		  			</v-col>

  		  				  </v-row>
		  					</v-card-text>
		  				</v-card>
		  			</v-col>
		  		</v-row>


          <v-col cols="12">
          	<v-data-table
					    :headers="headers2"
					    :items="horarios"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >
					  	<template v-slot:item.fecha="{ item }">
					    	<span v-if="item.descanso == 2">{{item.fecha}}</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 1">Sabado</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 2">Domingo</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 3">Lunes</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 4">Martes</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 5">Miércoles</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 6">Jueves</span>
					    	<span v-if="item.descanso == 1 && item.dia_descanso == 7">Viernes</span>
					    </template>

					    <template v-slot:item.actions="{ item }">
					    	<v-btn 
					    		color="error" 
					    		x-small
          				v-if="editedIndex < 0"
					    		@click="deleteItem2(item)"
					    		class="mr-2"
					    	>
						      <v-icon small> mdi-delete</v-icon>
					    	</v-btn>
					    </template>
					  </v-data-table>
          </v-col>

          <v-col cols="12" v-if="editedItem.motivo_rechazo">
          	<v-textarea
          		v-model="editedItem.motivo_rechazo"
          		filled
          		hide-details
          		readonly
          		dense
          		auto-grow
          		:rows="3"
          		label="Motivo"
          	/>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="black"
          tile
          dark
          small
          @click="openModalCambios.estatus = false"
        >
          <v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          class=""
          tile
          dark
          small
          @click="save"
          v-if="editedIndex < 0"
        >
          <v-icon small left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-dialog>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    props:[
    	'openModalCambios'
    ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: true,
      dialogDelete: false,
      planteles:[],
      
      editedIndex: -1,

      editedItem: {
        idsolicitud_cambio_horario:0,
				id_solicita:'',
				id_autoriza: null,
				id_usuario:null,
				estatus:0,
				deleted:0,
				fecha_creacion:null,
      },

      defaultItem: {
        idsolicitud_cambio_horario:0,
				id_solicita:'',
				id_autoriza: null,
				id_usuario:null,
				estatus:0,
				deleted:0,
				id_usuario_ultimo_cambio:0,
				fecha_creacion:null,
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      cambiohorario: [],
      headers: [
        { text: 'ID'              , value: 'idsolicitud_cambio_horario'    },
        { text: 'Solicitó'        , value: 'solicito'                      },
        { text: 'Aceptó'          , value: 'acepto'                        },
        { text: 'Trabajador'      , value: 'trabajador'                    },
        { text: 'Estatus'         , value: 'estatus'                       },
        { text: 'Fecha creación'  , value: 'fecha_creacion'                },
        { text: 'Actions'         , value: 'actions', sortable: false },
      ],

			usuarios:[],


			fecha: null,
			hora_inicio: null,
			hora_final: null,
			motivo: null,

			headers2: [
        { text: 'Fecha'       , value: 'fecha'       },
        { text: 'Hora inicio' , value: 'hora_inicio' },
        { text: 'Hora final'  , value: 'hora_final'  },
        { text: 'Descanso'    , value: 'descanso'    },
        { text: 'Motivo'      , value: 'motivo'      },
        { text: 'Actions'     , value: 'actions', sortable: false },
      ],
			horarios:[],

			descanso: null,
			dia_descanso:0,


    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar información' : 'Editar información'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getUsuarios( )
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.cambiohorario = []
        return this.$http.get('cambio.horario.list.mios/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.cambiohorario = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.cambiohorario.indexOf(item)
        this.editedItem = Object.assign({}, item)

        if( this.editedItem.horarios.length ){
        	this.horarios = this.editedItem.horarios

        }
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.cambiohorario.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItem2( item ){
      	let editedIndex = this.cambiohorario.indexOf(item)
        this.horarios = this.horarios.filter( el => el.fecha != item.fecha )
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('cambio.horario.update/' + this.editedItem.idsolicitud_cambio_horario, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('cambio.horario.update/' + this.editedItem.idsolicitud_cambio_horario, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.openModalCambios.estatus = false
        		this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {

        	this.editedItem.id_solicita = this.getdatosUsuario.iderp

        	this.editedItem['horarios'] = this.horarios
	        // Lo mandapos por el EP
          this.$http.post('cambio.horario.add', this.editedItem).then(response=>{
          	this.horarios = []
          	this.openModalCambios.estatus = false
        		this.close()
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].nombre_completo = response.data[i].nombre_completo.toUpperCase() 
    			}
    			this.usuarios = response.data
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      agregarHorario( ){

      	if( !this.motivo ){  return this.validarErrorDirecto('Favor de llenar los datos como: "horario" o "Descanso" y MOTIVO') }

      	this.horarios.push({
      		fecha       : this.fecha, 
      		hora_inicio : this.hora_inicio,
      		hora_final  : this.hora_final,
      		motivo      : this.motivo,
      		descanso    : this.descanso,
      		dia_descanso: this.dia_descanso
      	})
      	console.log( this.horarios )
      	this.fecha       = null
				this.hora_inicio = null
				this.hora_final  = null
				this.motivo      = ''
      }
    },
  }
</script>


